import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        fontSize: 15,
        flexDirection: "column",
    },
    section: {
        margin: 20,
        flexGrow: 1
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    nameDesc: {
        width: "50%",
        border: "1px solid black",
    },
    description: {
        width: "25%",
        border: "1px solid black",
    },
    rowDataPadding: {
        paddingLeft: '10',
        paddingVertical: '2'
    },
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginHorizontal: '20'
    },
    headerText: {
        width: "50%",
        padding: '3',
        paddingBottom: '4',
        textAlign: "center"
    },
    titleText: {
        textAlign: "center",
        paddingVertical: '2'
    },
    contentText: {
        textAlign: "center"
    }
});

const PdfDocument = (props) => {
    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.tableContainer}>
                        <View style={styles.row}>
                            <Text style={styles.headerText}>{props.data.rsvps.miqaat}</Text>
                            <Text style={styles.headerText}>{props.data.rsvps.miqaatDate}</Text>
                        </View>
                    </View>
                    <View style={styles.tableContainer}>
                        <View style={styles.row}>
                            <Text style={styles.headerText}>Thaals: {Math.ceil((props.data.rsvps?.adultCount + props.data.rsvps?.childrenCount) / 8)}</Text>
                            <Text style={styles.headerText}>Adults: {props.data.rsvps?.adultCount}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.headerText}></Text>
                            <Text style={styles.headerText}>Children: {props.data.rsvps?.childrenCount}</Text>
                        </View>
                    </View>
                    <View style={styles.tableContainer}>
                        <View style={styles.row}>
                            <Text style={[styles.nameDesc, styles.titleText]}>Name</Text>
                            <Text style={[styles.description, styles.titleText]}>Adults</Text>
                            <Text style={[styles.description, styles.titleText]}>Children</Text>
                        </View>
                    </View>
                    {
                        props.data.rsvps && props.data.rsvps.users.map(
                            user => {
                                if (user.adults !== '0') {
                                    return (
                                        <View style={styles.tableContainer}>
                                            <View style={styles.row}>
                                                <Text style={[styles.nameDesc, styles.rowDataPadding]}>{user.fullName}</Text>
                                                <Text style={[styles.description, styles.contentText, styles.rowDataPadding]}>{user.adults}</Text>
                                                <Text style={[styles.description, styles.contentText, styles.rowDataPadding]}>{user.children}</Text>
                                            </View>
                                        </View>
                                    )
                                }
                            }
                        )
                    }
                </Page>
            </Document>
        </>
    );
}

export default PdfDocument;