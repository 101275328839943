import React, { useState, useEffect } from "react";
import { timeConverter } from "../../../utils/timeConverter";
import CreateEventRsvpForm from "./CreateEventRsvpForm";
import { GET_INVITEE_COUNT, GET_ALL_MEMBERS } from "../gql/queries";
import { useQuery } from "@apollo/client";
import { Image, Transformation } from "cloudinary-react";

const EventCard = ({ event }) => {

    const [imageId, setImageId] = useState("");

    useEffect(() => {
        if (event.image) {
            let newImage = `${event.image}.png`
            setImageId(newImage)
        }
    }, [event.image])

    const { data: inviteeCountsData } = useQuery(GET_INVITEE_COUNT, { variables: { eventId: event._id } })

    const showModal = () => {
        const modal = document.querySelector('.rsvpModalParent')
        modal.style.visibility = 'visible'
    };

    const closeModal = () => {
        const modal = document.querySelector('.rsvpModalParent')
        modal.style.visibility = 'hidden'
    }
    const showInviteModal = () => {
        const modal = document.querySelector(`.inviteModal${event._id}`)
        modal.style.visibility = 'visible'
    };

    const closeInviteModal = () => {
        const modal = document.querySelector(`.inviteModal${event._id}`)
        modal.style.visibility = 'hidden'
    }

    const imageURL = "https://res.cloudinary.com/" + process.env.REACT_APP_CLOUD_NAME + "/image/upload/" + imageId;

    return (
        <>
            <div className="rsvpModalParent">
                <div className="modalSuccessful">
                    <h3>RSVP Successful!</h3>
                    <button className='ok' onClick={closeModal}><p>Close</p></button>
                </div>
            </div>
            <div className={`inviteModal${event._id} inviteModalParent`}>
                <Image cloudName={process.env.REACT_APP_CLOUD_NAME} publicId={imageId} alt={event.title}>
                    <Transformation width={"300"} height={"400"} gravity="auto" radius={"15"} crop="fill" border={"3px_solid_rgb:6B802A"} />
                </Image>
                <button style={{ marginTop: "10px" }} className='ok' onClick={closeInviteModal}><p>Close</p></button>
            </div>
            <div className="miqaatRow">
                <div className="miqaatTitle">
                    <p><b>{event.title}</b></p>
                </div>
                <p><b>{event.hijriDate}</b></p>
                <p><b>{timeConverter(event.date)}, {event.time}</b></p>
                <p><b>{event.venue}</b></p>
                <p style={{ fontSize: "16px" }}><i>Hosts: {event.hosts?.map(host => host.fullName).join(", ")}</i></p>
                {event.image && <a href={imageURL} target="_blank" rel="noopener noreferrer" style={{ color: "#a4800f" }}>View Invite</a>}
                <hr className="miqaatRowLine" />
                {event.eventType === "private" ? <>
                    <p>Men:&nbsp;<b>{inviteeCountsData?.getInviteeCount?.men}</b>&nbsp;
                        Women:&nbsp;<b>{inviteeCountsData?.getInviteeCount?.women}</b>&nbsp;
                        Children:&nbsp;<b>{inviteeCountsData?.getInviteeCount?.children}</b></p>
                </> : <p><b>Izan to All</b></p>
                }
                <hr className="miqaatRowLine" />
                <CreateEventRsvpForm event={event} showModal={showModal} />
                <hr className="miqaatRowLine" />
                <div className="hostsDiv">
                    <p style={{ fontSize: "14px" }}>
                        {event.program}
                    </p>
                </div>
            </div>
        </>
    )
}

export default EventCard;
