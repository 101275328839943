import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        fontSize: 15,
        flexDirection: "column",
    },
    section: {
        margin: 20,
        flexGrow: 1
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    namedesc: {
        width: "40%",
        border: "1px solid black",
    },
    description: {
        width: "20%",
        border: "1px solid black",
    },
    rowDataPadding: {
        paddingLeft: '10',
        paddingVertical: '2'
    },
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginHorizontal: '20'
    },
    headerText: {
        width: "50%",
        padding: '3',
        paddingBottom: '4',
        textAlign: "center"
    },
    countText: {
        padding: '2',
        paddingBottom: '4',
        textAlign: "center"
    },
    titleText: {
        textAlign: "center",
        paddingVertical: '2'
    },
    contentText: {
        textAlign: "center"
    }
});

const EventListPdfDocument = (props) => {
    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.tableContainer}>
                        <View style={styles.row}>
                            <Text style={styles.headerText}>{props.data.rsvpsForHostsEvents.event}</Text>
                            <Text style={styles.headerText}>{props.data.rsvpsForHostsEvents.eventDate}</Text>
                        </View>
                    </View>
                    <View style={styles.tableContainer}>
                        <View style={styles.row}>
                            <Text style={styles.headerText}>Thaals: {Math.ceil((props.data.rsvpsForHostsEvents?.menCount + props.data.rsvpsForHostsEvents?.womenCount + props.data.rsvpsForHostsEvents?.childrenCount) / 8)}</Text>
                            <Text style={styles.headerText}>Men: {props.data.rsvpsForHostsEvents?.menCount}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.headerText}></Text>
                            <Text style={styles.headerText}>Women: {props.data.rsvpsForHostsEvents?.womenCount}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.headerText}></Text>
                            <Text style={styles.headerText}>Children: {props.data.rsvpsForHostsEvents?.childrenCount + props.data.rsvpsForHostsEvents?.toddlerCount}</Text>
                        </View>
                    </View>
                    <View style={styles.tableContainer}>
                        <View style={styles.row}>
                            <Text style={[styles.namedesc, styles.titleText]}>Name</Text>
                            <Text style={[styles.description, styles.titleText]}>Men</Text>
                            <Text style={[styles.description, styles.titleText]}>Women</Text>
                            <Text style={[styles.description, styles.titleText]}>Children</Text>
                        </View>
                    </View>
                    {
                        props.data.rsvpsForHostsEvents && props.data.rsvpsForHostsEvents.users.map(
                            user => {
                                if (user.men !== '0' || user.women !== 0) {
                                    return (
                                        <View style={styles.tableContainer}>
                                            <View style={styles.row}>
                                                <Text style={[styles.namedesc, styles.rowDataPadding, styles.width]}>{(props.data.rsvpsForHostsEvents.womenOnly === null || !props.data.rsvpsForHostsEvents.womenOnly) ? user.fullName : (user.spouseName ? user.spouseName : user.fullName)}</Text>
                                                <Text style={[styles.description, styles.contentText, styles.rowDataPadding]}>{user.men}</Text>
                                                <Text style={[styles.description, styles.contentText, styles.rowDataPadding]}>{user.women}</Text>
                                                <Text style={[styles.description, styles.contentText, styles.rowDataPadding]}>{Number(user.children) + Number(user.toddlers)}</Text>
                                            </View>
                                        </View>
                                    )
                                }
                            }
                        )
                    }
                </Page>
            </Document>
        </>
    );
}

export default EventListPdfDocument;