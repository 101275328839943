import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import { timeConverter } from "../../../utils/timeConverter";
import { useLazyQuery, useQuery } from "@apollo/client";
import { PDFDownloadLink } from '@react-pdf/renderer';
import MiqaatRsvpListPdf from "./MiqaatRsvpListPdf";
import { GET_MIQAATS_FOR_USER, GET_RSVP_FOR_MIQAAT } from "../gql/queries";
import { useMediaQuery } from "../../../utils/deviceChecker";

const UserMiqaat = () => {

    const { data: userMiqaatsData } = useQuery(GET_MIQAATS_FOR_USER);
    const userMiqaats = userMiqaatsData?.userMiqaats || [];

    const isDesktop = useMediaQuery("(min-width: 1258px)");

    const [getRsvps, { data: rsvpData }] = useLazyQuery(GET_RSVP_FOR_MIQAAT, { variables: { miqaatId: "" } });

    const showGenerateButtons = () => {
        let allGenerateDivs = document.getElementsByClassName("generateDiv");
        if (allGenerateDivs.length > 0) {
            for (let i = 0; i < allGenerateDivs.length; i++) {
                allGenerateDivs[i].style.display = "none";
            }
        }
        let allGenerateBtns = document.getElementsByClassName("generateBtn");
        if (allGenerateBtns.length > 0) {
            for (let i = 0; i < allGenerateBtns.length; i++) {
                allGenerateBtns[i].style.display = "block";
            }
        }
    }

    const fetchRsvpCount = async (event) => {
        showGenerateButtons();
        let id = event.target.id;
        const generateButton = document.querySelector(`#button${id}`)
        generateButton.style.display = "none";
        const generateDiv = document.querySelector(`#countAndLinkInfo${id}`);
        generateDiv.style.display = 'flex'
        generateDiv.className = 'generateDiv'
        let rsvps = await getRsvps({
            variables: { miqaatId: id }
        });
    }

    const isShowButtons = (data, buttonId) => {
        if (data === null) {
            console.log("No data found for list generation");
            return false;
        }
        if (data.rsvps === undefined || data.rsvps.length === 0) {
            console.log("No data found for list generation");
            return false;
        }
        return data.rsvps.miqaatId === buttonId;
    };

    return (
        <>
            <SectionHeader title="Your Miqaats" className='sectionHeader' />
            <div className="dishContainer">
                {
                    userMiqaats && userMiqaats.length > 0 ? userMiqaats.map((miqaat) => (
                        <>
                            <div id="cookLoginDishesRow" className="dishesRow">
                                <p className="miqaatText">{miqaat.title}</p>
                                <p>{timeConverter(miqaat.date)}, {miqaat.time}</p>
                                <div id={"form" + miqaat._id} className="generateBtnForm">
                                    <div id={"button" + miqaat._id} className="generateBtn">
                                        <button id={miqaat._id} className="sizeButtons" onClick={fetchRsvpCount}>Generate</button>
                                    </div>
                                    {isDesktop ? (
                                        <div id={"countAndLinkInfo" + miqaat._id}>
                                            {rsvpData && isShowButtons(rsvpData, miqaat._id) &&
                                                <>
                                                    <p>Adults:&nbsp;<b>{rsvpData.rsvps?.adultCount}</b></p>
                                                    <p>Children:&nbsp;<b>{rsvpData.rsvps?.childrenCount}</b></p>
                                                    <p>Thaals:&nbsp;<b>{Math.ceil((rsvpData.rsvps?.adultCount + rsvpData.rsvps?.childrenCount) / 8)}</b></p>
                                                </>
                                            }
                                            {
                                                rsvpData && isShowButtons(rsvpData, miqaat._id) &&
                                                <PDFDownloadLink
                                                    fileName={"List-" + miqaat.title.replace(/\s/g, '') + ".pdf"}
                                                    document={<MiqaatRsvpListPdf data={rsvpData} />}
                                                    className="csvLink">List</PDFDownloadLink>
                                            }
                                        </div>
                                    ) : (
                                        <div id={"countAndLinkInfo" + miqaat._id}>
                                            {rsvpData && isShowButtons(rsvpData, miqaat._id) &&
                                                <div className="rsvpDataCounts">
                                                    <p>Adults:&nbsp;<b>{rsvpData.rsvps?.adultCount}</b></p>
                                                    <p>Children:&nbsp;<b>{rsvpData.rsvps?.childrenCount}</b></p>
                                                    <p>Thaals:&nbsp;<b>{Math.ceil((rsvpData.rsvps?.adultCount + rsvpData.rsvps?.childrenCount) / 8)}</b></p>
                                                    {
                                                        rsvpData && isShowButtons(rsvpData, miqaat._id) &&
                                                        <PDFDownloadLink
                                                            fileName={"List-" + miqaat.title.replace(/\s/g, '') + ".pdf"}
                                                            document={<MiqaatRsvpListPdf data={rsvpData} />}
                                                            className="csvLink">List</PDFDownloadLink>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr className="dishRowLine" />
                        </>
                    )) : <p className="errMsg" style={{ textAlign: "center", fontSize: "20px", paddingBottom: "20px" }}>
                        You are not hosting any upcoming miqaats
                    </p>

                }
            </div>
        </>
    );
}

export default UserMiqaat;
