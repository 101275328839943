import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import { timeConverter } from "../../../utils/timeConverter";
import { useLazyQuery, useQuery } from "@apollo/client";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useMediaQuery } from "../../../utils/deviceChecker";
import { GET_EVENTS_FOR_HOST, GET_RSVP_FOR_HOST_EVENT } from "../gql/queries";
import EventListPdfDocument from "./EventRsvpListPdf";
import { Link } from "react-router-dom";

const HostsEvents = () => {

    const { data: hostsEventsData } = useQuery(GET_EVENTS_FOR_HOST);
    const hostsEvents = hostsEventsData?.hostsEvents || [];

    const isDesktop = useMediaQuery("(min-width: 1258px)");

    const [getRsvpsForHostsEvents, { data: rsvpData }] = useLazyQuery(GET_RSVP_FOR_HOST_EVENT, { variables: { eventId: "" } });

    const showGenerateButtons = () => {
        let allGenerateDivs = document.getElementsByClassName("generateDiv");
        if (allGenerateDivs.length > 0) {
            for (let i = 0; i < allGenerateDivs.length; i++) {
                allGenerateDivs[i].style.display = "none";
            }
        }
        let allGenerateBtns = document.getElementsByClassName("generateBtn");
        if (allGenerateBtns.length > 0) {
            for (let i = 0; i < allGenerateBtns.length; i++) {
                allGenerateBtns[i].style.display = "block";
            }
        }
    }

    const fetchRsvpCount = async (event) => {
        showGenerateButtons();
        let id = event.target.id;
        const generateButton = document.querySelector(`#button${id}`)
        generateButton.style.display = "none";
        const generateDiv = document.querySelector(`#countAndLinkInfo${id}`);
        generateDiv.style.display = 'flex'
        generateDiv.className = 'generateDiv'
        let rsvps = await getRsvpsForHostsEvents({
            variables: { eventId: id }
        });
    }

    const isShowButtons = (rsvpData, buttonId) => {
        if (rsvpData === null) {
            console.log("No data found for list generation");
            return false;
        }
        if (rsvpData.rsvpsForHostsEvents === undefined || rsvpData.rsvpsForHostsEvents.length === 0) {
            console.log("No data found for list generation");
            return false;
        }
        return rsvpData.rsvpsForHostsEvents.eventId === buttonId;
    };

    return (
        <>
            <SectionHeader title="Your Events" className='sectionHeader' />
            <div className="dishContainer">
                {
                    hostsEvents && hostsEvents.length > 0 ? hostsEvents.map((event) => (
                        <>
                            <div id="cookLoginDishesRow" className="dishesRow">
                                <Link to={`/manageEvent/${event._id}`} style={{ color: "#a4800f" }}> <p className="miqaatText">{event.title}</p></Link>
                                <p>{timeConverter(event.date)}, {event.time}</p>
                                <div id={"form" + event._id} className="generateBtnForm">
                                    <div id={"button" + event._id} className="generateBtn">
                                        <button id={event._id} className="sizeButtons" onClick={fetchRsvpCount}>Generate</button>
                                    </div>
                                    {isDesktop ? (
                                        <div id={"countAndLinkInfo" + event._id}>
                                            {rsvpData && isShowButtons(rsvpData, event._id) &&
                                                <>
                                                    <p>Men:&nbsp;<b>{rsvpData.rsvpsForHostsEvents?.menCount}</b></p>
                                                    <p>Women:&nbsp;<b>{rsvpData.rsvpsForHostsEvents?.womenCount}</b></p>
                                                    <p>Children:&nbsp;<b>{rsvpData.rsvpsForHostsEvents?.childrenCount + rsvpData.rsvpsForHostsEvents?.toddlerCount}</b></p>
                                                    <p>Thaals:&nbsp;<b>{Math.ceil((rsvpData.rsvpsForHostsEvents?.menCount + rsvpData.rsvpsForHostsEvents?.womenCount + rsvpData.rsvpsForHostsEvents?.childrenCount) / 8)}</b></p>
                                                </>
                                            }
                                            {
                                                rsvpData && isShowButtons(rsvpData, event._id) &&
                                                <PDFDownloadLink
                                                    fileName={"List-" + event.title.replace(/\s/g, '') + ".pdf"}
                                                    document={<EventListPdfDocument data={rsvpData} />}
                                                    className="csvLink">List</PDFDownloadLink>
                                            }
                                        </div>
                                    ) : (
                                        <div id={"countAndLinkInfo" + event._id}>
                                            {rsvpData && isShowButtons(rsvpData, event._id) &&
                                                <div className="rsvpDataCounts">
                                                    <p>Men:&nbsp;<b>{rsvpData.rsvpsForHostsEvents?.menCount}</b></p>
                                                    <p>Women:&nbsp;<b>{rsvpData.rsvpsForHostsEvents?.womenCount}</b></p>
                                                    <p>Children:&nbsp;<b>{rsvpData.rsvpsForHostsEvents?.childrenCount}</b></p>
                                                    <p>Thaals:&nbsp;<b>{Math.ceil((rsvpData.rsvpsForHostsEvents?.menCount + rsvpData.rsvpsForHostsEvents?.womenCount + rsvpData.rsvpsForHostsEvents?.childrenCount) / 8)}</b></p>
                                                    {
                                                        rsvpData && isShowButtons(rsvpData, event._id) &&
                                                        <PDFDownloadLink
                                                            fileName={"List-" + event.title.replace(/\s/g, '') + ".pdf"}
                                                            document={<EventListPdfDocument data={rsvpData} />}
                                                            className="csvLink">List</PDFDownloadLink>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr className="dishRowLine" />
                        </>
                    )) : <p className="errMsg" style={{ textAlign: "center", fontSize: "20px", paddingBottom: "20px" }}>
                        You are not hosting any upcoming events
                    </p>

                }
            </div>
        </>
    );
}

export default HostsEvents;