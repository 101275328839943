import SectionHeader from "../../../components/SectionHeader";
import { timeConverter } from "../../../utils/timeConverter";
import React from "react";
import { useQuery } from "@apollo/client";
import { GET_EVENT_RSVP_FOR_USER } from "../gql/queries";

const UserEventRsvps = () => {
    const { data: userEventRsvpsData } = useQuery(GET_EVENT_RSVP_FOR_USER);
    const userEventRsvps = userEventRsvpsData?.usersEventRsvps || [];

    let currentDate = new Date();
    let yesterday = currentDate.setDate(currentDate.getDate() - 1).toString();
    let sortByEvent = (rsvps) => {
        let rsvpList = [...rsvps];
        return rsvpList.sort((a, b) => a.event.date - b.event.date)
            .filter(rsvp => rsvp.event.date > yesterday)
    }
    let isAnyRsvpForUpcoming = (rsvps) => {
        let rsvpsUpcoming = rsvps.filter(rsvp => rsvp.event.date > yesterday)
        return rsvpsUpcoming.length > 0;
    }
    return (
        <>
            <SectionHeader title="Your Rsvps" className='sectionHeader' />
            <div className="dishContainer">
                {
                    userEventRsvps && isAnyRsvpForUpcoming(userEventRsvps) ? sortByEvent(userEventRsvps).map((rsvp) => (
                        <div>
                            <div id="eventsRsvpsRow" className="dishesRow">
                                <p className="miqaatText">{rsvp.event.title}</p>
                                <p>{timeConverter(rsvp.event.date)}, {rsvp.event.time}</p>
                                <p>Men: {rsvp.men}</p>
                                <p>Women: {rsvp.women}</p>
                                <p>Children: {Number(rsvp.children) + Number(rsvp.toddlers)}</p>
                            </div>
                            <hr className="dishRowLine" />
                        </div>
                    )) : <p className="errMsg" style={{ textAlign: "center", fontSize: "20px", paddingBottom: "20px" }}>
                        No RSVPs for upcoming events
                    </p>
                }
            </div>
        </>
    )
}

export default UserEventRsvps;
