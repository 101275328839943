import React, { useState, useEffect } from "react";
import Nav from "../../../components/Nav";
import Auth from "../../../utils/auth";
import { Navigate } from "react-router-dom";
import EventCard from "./EventCard";
import { timeConverter } from "../../../utils/timeConverter";
import CreateEventRsvpForm from "./CreateEventRsvpForm";
import { GET_INVITEE_COUNT, GET_ALL_MEMBERS, GET_EVENT, GET_ALL_USERS_EVENTS } from "../gql/queries";
import { useQuery } from "@apollo/client";
import { Image, Transformation } from "cloudinary-react";
import { useParams } from "react-router-dom";

const SingleEventRsvp = () => {
    const { eventId: eventParam } = useParams();
    const { data: eventData } = useQuery(GET_EVENT, { variables: { eventId: eventParam } });
    const event = eventData?.event || {};

    const { data: allEventsData } = useQuery(GET_ALL_USERS_EVENTS);
    let events = allEventsData?.allUsersEvents || [];

    const isEventIdInUserEvents = () => {
        for (let i = 0; i < events.length; i++) {
            if (events[i]?._id === eventParam) {
                return true;
            }
        }
        return false;
    }

    return (
        <>
            {!Auth.loggedIn() && <Navigate to="/login" />}
            <div className="navAndHeader">
                <Nav />
            </div>
            <div className="mainContainer">
                {events && isEventIdInUserEvents() ?
                    <>
                        <h1>Event RSVP</h1>
                        <div className="singleEventContainer">
                            <EventCard event={event} />
                        </div>
                    </> : <p style={{ marginTop: "5%", color: "#a4800f", fontSize: "20px" }}>This invite link is invalid</p>}
            </div>
        </>
    )
};

export default SingleEventRsvp;