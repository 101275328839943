import { gql } from "@apollo/client";

export const GET_ALL_USERS_EVENTS = gql`
query allUsersEvents {
  allUsersEvents {
    _id
    title
    hijriDate
    date
    program
    time
    venue
    image
    womenOnly
    eventType
    hosts {
      _id
      fullName
    } 
  }
}
`

export const GET_ALL_MEMBERS = gql`
  query allMembers {
    allMembers {
      _id
      fullName
    }
  }
`

export const GET_ALL_EVENTS = gql`
query getAllEvents {
  allEvents {
    _id
    hijriDate
    date
    time
    program
    category
    title
    womenOnly
  }
}
`

export const GET_EVENT_RSVP_FOR_USER = gql`
  query usersEventRsvps {
    usersEventRsvps {
       _id
    men
    women
    children
    toddlers
    event {
      title
      _id
      date
      time
      womenOnly
    }
    }
  }
`

export const GET_USER_EVENT_RSVP_FOR_PARTICULAR_EVENT = gql`
  query usersEventRsvpsForEvent($eventId: ID!) {
    usersEventRsvpsForEvent(eventId: $eventId) {
       _id
    men
    women
    children
    toddlers
    event {
      title
      _id
      date
      time
      womenOnly
    }
    }
  }
`

export const GET_EVENTS_FOR_HOST = gql`
  query getHostsEvents {
    hostsEvents {
      date
      hijriDate
      title
      _id
      time
      eventType
    }
  }
`

export const GET_RSVP_FOR_HOST_EVENT = gql`
  query getRsvpsForHostsEvents($eventId: ID!) {
    rsvpsForHostsEvents(eventId: $eventId) {
      eventId
      menCount
      womenCount
      childrenCount
      toddlerCount
      event
      eventDate
      womenOnly
      users {
        fullName
        spouseName
        men
        women
        children
        toddlers
      }
    }
  }
`

export const GET_INVITEE_COUNT = gql`
  query getInviteeCount($eventId: ID) {
  getInviteeCount(eventId: $eventId) {
    children
    men
    women
  }
}
`

export const GET_EVENT = gql`
  query getEvent($eventId: ID!) {
    event(eventId: $eventId) {
      _id
      title
      venue
      hijriDate
      date
      time
      image
      womenOnly
      eventType
      hosts {
      _id
      fullName
      } 
    }
  }
`

export const GET_INVITEES_FOR_EVENT = gql`
  query invitees($eventId: ID!) {
  invitees(eventId: $eventId) {
    _id
    user {
      _id
      fullName
      spouseName
    }
    men
    women
    children
  }
}
`

export const GET_INVITEE_RSVP_CHECK = gql`
query inviteeRsvpCheck($eventId: ID) {
  inviteeRsvpCheck(eventId: $eventId) {
    hasRsvp
    userId
  }
}
`